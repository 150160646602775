import { Card, Grid, Hidden } from '@mui/material';
import * as Schema from 'generated/graphql/schema';
import React, { FC, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import SearchBar, { SearchValue } from '@/components/bars/search-bar';
import Paginator from '@/components/paginator';
import TimePicker from '@/components/pickers/time-picker';
import LineCardConfigurationMenu from '@/views/lines/line-card-configuration-menu';

const useStyles = makeStyles()((theme) => ({
  root: {
    // Visible overflow is necessary for the search bar
    overflow: 'visible',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(),
    '@media print': {
      display: 'none',
    },
  },
  gridItemXXL: {
    '@media (min-width: 1650px)': {
      webkitFlexBasis: '50%',
      flexBasis: '50%',
      webkitBoxFlex: 0,
      webkitFlexGrow: 0,
      msFlexPositive: 0,
      flexGrow: 0,
      maxWidth: '50%',
    },
  },
  rightContainerXXL: {
    '@media (min-width: 1650px)': {
      justifyContent: 'flex-end',
    },
  },
}));

type LinesOverviewTopBarProperties = {
  search: SearchValue[];
  page: number;
  pageSize: number;
  filteredLines: Schema.LinesListSimpleQuery['lines'];
  timeAtLoad: Date;
  handleSearchChange: (search: SearchValue[]) => void;
  handlePageChange: (page: number) => void;
};

const LinesOverviewTopBar: FC<LinesOverviewTopBarProperties> = ({
  search,
  page,
  filteredLines,
  pageSize,
  handlePageChange,
  handleSearchChange,
  timeAtLoad,
}) => {
  const { classes } = useStyles();

  const suggestions: SearchValue[] = useMemo(() => {
    return filteredLines
      .filter((line) => {
        if (!search.length) {
          return true;
        }

        if (!line?.name) {
          return false;
        }

        return search.some((searchTerm) =>
          [line.name.toLowerCase(), (line.description || '').toLowerCase()].some(
            (entry) => searchTerm?.value?.value && entry.includes(searchTerm.value.value),
          ),
        );
      })
      .map((line) => {
        const label = line.name! || line.description!;
        return {
          label,
          value: {
            type: 'string',
            value: label.toLowerCase(),
          },
        };
      });
  }, [filteredLines, search]);

  const totalCount = filteredLines.length;

  return (
    <Card elevation={1} className={classes.root}>
      <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xs={12} className={classes.gridItemXXL}>
          <SearchBar suggestions={suggestions} value={search} handleChange={handleSearchChange} />
        </Grid>
        <Grid item xs={12} className={classes.gridItemXXL}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.rightContainerXXL}
            spacing={2}
          >
            <Grid item mt={1} mr={2}>
              <TimePicker showUndo={false} showCustomTimePicker={false} hideSelected />
            </Grid>
            <Grid item>
              <Grid container alignItems="center" justifyContent="space-between" flexWrap="nowrap">
                <Hidden mdDown>
                  <Paginator
                    totalPages={Math.ceil(totalCount / pageSize)}
                    currentPage={page}
                    onCurrentPageChange={handlePageChange}
                    totalCount={totalCount}
                    pageSize={pageSize}
                  />
                </Hidden>
                <LineCardConfigurationMenu timeAtLoad={timeAtLoad} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default LinesOverviewTopBar;
