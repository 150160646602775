import { Button, CircularProgress, Fab, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { FabProps } from '@mui/material/Fab';
import { TooltipProps } from '@mui/material/Tooltip';
import { green } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonMutationLoading: {
    marginRight: theme.spacing(1),
    marginTop: '-2px',
  },
  buttonMutationWithIconLoading: {
    position: 'absolute',
    left: '5px',
  },
  iconButtonMutationLoading: {
    marginTop: theme.spacing(0.5),
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    left: '0',
    right: '0',
  },

  addCustomColor: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  addIconCustomColor: {
    color: theme.palette.error.main,
  },
}));

interface Properties extends IconButtonProps {
  disabled?: boolean;
  loading?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  tooltipMsg?: string;
  tooltipMsgDisabled?: string;
  spanClass?: string;
  loadingColor?: string;
  children: React.ReactNode;
  overrideProgressStyle?: React.CSSProperties;
  isRed?: boolean;
  overrideStyle?: object;
  withIcon?: boolean;
  muiVariant?: ButtonProps['variant'];
}

type GenericButtonProps = ButtonProps & IconButtonProps & FabProps;

export const MutationButton: FC<Properties & Omit<GenericButtonProps, 'variant'> & { variant?: 'fab' | 'icon' }> = ({
  children,
  disabled = false,
  tooltipPlacement = 'top',
  tooltipMsg = '',
  tooltipMsgDisabled = '',
  spanClass,
  loading,
  onClick,
  loadingColor,
  withIcon,
  variant,
  overrideProgressStyle,
  isRed,
  color,
  muiVariant,
  ...rest
}) => {
  const { classes } = useStyles();

  const ButtonComponent: React.FC<GenericButtonProps> =
    variant === 'fab' ? Fab : variant === 'icon' ? IconButton : Button;

  return (
    <Tooltip placement={tooltipPlacement} title={disabled ? tooltipMsgDisabled : tooltipMsg}>
      <span className={`${isRed ? classes.addIconCustomColor : ''} ${spanClass}`}>
        <ButtonComponent
          {...rest}
          color={color ?? (isRed ? 'inherit' : 'primary')}
          disabled={disabled || loading}
          onClick={(e) => !disabled && onClick?.(e)}
          variant={muiVariant as any}
        >
          {loading && (
            <CircularProgress
              size={withIcon ? 32 : 18}
              className={`${classes.buttonMutationLoading} ${withIcon ? classes.buttonMutationWithIconLoading : ''}`}
              style={{
                color: loadingColor ?? green[500],
                ...(overrideProgressStyle ?? {}),
              }}
            />
          )}
          {children}
        </ButtonComponent>
      </span>
    </Tooltip>
  );
};

/**
 * Quickly set up an `IconButton` wrapped in a `Tooltip` for displaying a message when it's disabled.
 *
 * If a disabled prop has been passed, this will also make sure to block any onClick action, while
 * it is disabled.
 *
 * If a loading prop is passed in, it will automatically handle adding and removing loading spinner
 * around the icon.
 */

export const MutationIconButton: FC<Omit<Properties, 'withIcon'> & IconButtonProps> = ({
  children,
  disabled = false,
  tooltipPlacement = 'top',
  tooltipMsg = '',
  tooltipMsgDisabled = '',
  spanClass,
  loading,
  onClick,
  loadingColor,
  overrideProgressStyle,
  isRed,
  color,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <Tooltip placement={tooltipPlacement} title={disabled ? tooltipMsgDisabled : tooltipMsg}>
      <span className={`${isRed ? classes.addIconCustomColor : ''} ${spanClass}`}>
        <IconButton
          color={color ?? (isRed ? 'inherit' : 'primary')}
          {...rest}
          disabled={disabled || loading}
          onClick={(e) => !disabled && onClick?.(e)}
          size="large"
        >
          {children}
        </IconButton>
        {loading && (
          <CircularProgress
            size={40}
            className={classes.iconButtonMutationLoading}
            style={{
              color: loadingColor ?? green[500],
              ...(overrideProgressStyle ?? {}),
            }}
          />
        )}
      </span>
    </Tooltip>
  );
};
