import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  requiredFieldDesc: {
    fontSize: '11px',
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '10px',
  },
}));

interface Properties {
  overrideTitle?: string;
}

export default function WrapRequiredFieldsDescriptionComponent(props: Properties) {
  const [t] = useTranslation();
  const { classes } = useStyles();
  const title =
    props?.overrideTitle ??
    t(['shared:requiredFieldDesc'], { defaultValue: 'All fields marked with an asterisk (*) are required.' });
  return (
    <div>
      <Typography className={classes.requiredFieldDesc}>{title}</Typography>
    </div>
  );
}
