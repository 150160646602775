import { GetStaticProps, NextPage } from 'next';

import LineCardPage from '@/views/lines/overview-page';

const Lines: NextPage = () => {
  // To align times across line cards, send it as a prop from the parent
  const timeAtLoad = new Date();

  return <LineCardPage timeAtLoad={timeAtLoad} />;
};

export const getStaticProps: GetStaticProps = async () => ({
  props: {
    namespaces: ['line'],
  },
});

export default Lines;
