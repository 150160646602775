import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

interface Properties {
  open: boolean;
  handleResponse: (discard: boolean) => void;
}

class DiscardChangesDialog extends React.Component<Properties & WithTranslation, {}> {
  render() {
    const { t, open } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.onDiscard}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(['shared:discardMessage'], {
                defaultValue: 'You have unsaved changes. Are you sure you want to close?',
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onDiscard} color="primary">
              {t(['shared:discard'], { defaultValue: 'Discard' })}
            </Button>
            <Button onClick={this.onSave} color="primary" autoFocus>
              {t(['shared:save'], { defaultValue: 'Save' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private onDiscard = () => this.props.handleResponse(true);

  private onSave = () => this.props.handleResponse(false);
}
const enhance = compose<unknown, Properties>(withTranslation(['shared']));

export default enhance(DiscardChangesDialog as React.ComponentType<unknown>);
